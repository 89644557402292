export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const PYLADIES_EMAIL = "bratislava@pyladies.com";

export const SOCIAL_MEDIA_CONFIG = [
  {
    name: "Facebook",
    icon: <i className="fab fa-facebook fa-4x" aria-hidden="true" />,
    pathname: "https://www.facebook.com/groups/PyLadiesBratislava/",
  },
  {
    name: "Slack",
    icon: <i className="fab fa-slack fa-4x" aria-hidden="true" />,
    pathname: "https://pyladies.slack.com/archives/CGF33FSMD",
  },
  {
    name: "GitHub",
    icon: <i className="fab fa-github fa-4x" aria-hidden="true" />,
    pathname: "https://github.com/pyladies-bratislava",
  },
  {
    name: "Email",
    icon: <i className="fas fa-at fa-4x" aria-hidden="true" />,
    pathname: "#",
  },
];
